@charset "UTF-8";
.queryFirst .page-header .img {
  width: 54%;
  max-width: 183px;
}
.queryFirst__block {
  padding: 30px 15px;
}
.queryFirst__block .inner {
  margin: 0 auto;
  padding: 0;
  max-width: 1100px;
}
.queryFirst__anchor {
  margin: 30px auto 38px;
}
.queryFirst__step__intro {
  padding: 40px 15px 23px;
}
.queryFirst__step__anchor {
  margin: 0 auto;
  padding: 0 15px 45px;
  width: 100%;
  max-width: 330px;
}
@media screen and (max-width: 991px) {
  .queryFirst__step__anchor .flexItem:not(:first-child) {
    margin-top: 47px;
    position: relative;
  }
  .queryFirst__step__anchor .flexItem:not(:first-child)::before {
    content: "";
    display: block;
    width: 30px;
    height: 20px;
    background-image: url(../images/common/icon_arw_down.svg);
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: contain;
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, -35px);
  }
}
.queryFirst__step__anchor .anchorBtn {
  display: flex;
  text-decoration: none;
  background-color: #0072BC;
  border: 1px solid #0072BC;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  transition: all 0.3s ease;
}
.queryFirst__step__anchor .anchorBtn::after {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  background-image: url(../images/common/icon_arw_btn3.svg);
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: contain;
  position: absolute;
  right: 15px;
  top: 50%;
  transition: all 0.3s ease;
  transform: translate(0, -50%) rotate(90deg);
}
.queryFirst__step__anchor .anchorBtn .step {
  align-items: stretch;
  padding: 10px;
  width: 58px;
  color: #FFF;
  font-size: 13px;
  font-weight: 500;
  text-align: center;
  line-height: 1;
}
.queryFirst__step__anchor .anchorBtn .step .flex-align-center {
  display: flex;
  align-items: center;
  height: 100%;
}
.queryFirst__step__anchor .anchorBtn .step .flex-align-center span {
  margin: 0 auto;
}
.queryFirst__step__anchor .anchorBtn .step .num {
  display: block;
  width: 100%;
  color: #FFF;
  font-size: 25px;
  font-weight: 700;
  font-style: normal;
  line-height: 1;
}
.queryFirst__step__anchor .anchorBtn .ttl {
  display: flex;
  align-items: center;
  padding: 10px;
  width: calc(100% - 58px);
  font-size: 15px;
  font-weight: 500;
  line-height: 1.55;
  background-color: #FFF;
}
.queryFirst__step__block {
  border-top: 1px solid #C0DBEC;
  border-bottom: 1px solid #C0DBEC;
  position: relative;
}
.queryFirst__step__block.step02, .queryFirst__step__block.step03, .queryFirst__step__block.step04, .queryFirst__step__block.step05, .queryFirst__step__block.step06 {
  margin-top: 50px;
  position: relative;
}
.queryFirst__step__block.step02::before, .queryFirst__step__block.step03::before, .queryFirst__step__block.step04::before, .queryFirst__step__block.step05::before, .queryFirst__step__block.step06::before {
  content: "";
  display: block;
  width: 30px;
  height: 20px;
  background-image: url(../images/common/icon_arw_down.svg);
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: contain;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, -35px);
}
.queryFirst__step__block.step06 {
  margin-bottom: 50px;
}
.queryFirst__step__head .step__title {
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.queryFirst__step__head .step__title__num {
  align-items: stretch;
  padding: 10px;
  color: #0072BC;
  font-size: 13px;
  font-weight: 500;
  text-align: center;
  line-height: 1;
  position: relative;
}
.queryFirst__step__head .step__title__num::after {
  content: "";
  display: block;
  width: 2px;
  height: 60%;
  border-left: 2px solid #0072BC;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
}
.queryFirst__step__head .step__title__num .flex-align-center {
  display: flex;
  align-items: center;
  height: 100%;
}
.queryFirst__step__head .step__title__num .num {
  display: block;
  margin-top: 5px;
  width: 100%;
  color: #0072BC;
  font-size: 25px;
  font-weight: 700;
  font-style: normal;
  line-height: 1;
}
.queryFirst__step__head .step__title__txt {
  padding: 8px;
}
.queryFirst__step__head .step__title__txt .ttl {
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.05em;
}
.queryFirst__step__head .step__leadTxt {
  margin-top: 10px;
}
.queryFirst__step__body ol > li {
  margin-left: 1.5em;
  list-style: decimal outside;
}
.queryFirst__step__body ul {
  margin-top: 7px;
  padding-left: 24px;
}
.queryFirst__step__body ul > li {
  position: relative;
}
.queryFirst__step__body ul > li::before {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  background-color: #C0DBEC;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 0;
  transform: translate(-24px, 10px);
}
.queryFirst__select__buttons {
  margin-top: 20px;
}
.queryFirst__select__buttons .flexBox {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 -7px;
}
.queryFirst__select__buttons .flexItem {
  margin: 7px 0;
  padding: 0 7px;
  width: 50%;
}
.queryFirst__select__buttons .selectBtn {
  padding: 9px 35px 9px 10px;
  width: 100%;
  height: 100%;
  color: #FFF;
  font-size: 13px;
  font-weight: 500;
  text-align: center;
  line-height: 1.54;
  letter-spacing: 0;
  background-color: #0072BC;
  border: 1px solid #0072BC;
  border-radius: 5px;
  cursor: pointer;
}
.queryFirst__select__buttons .selectBtn.on {
  color: #0072BC;
  background-color: #FFF;
  pointer-events: none;
}
.queryFirst__select__buttons .selectBtn:hover {
  color: #0072BC;
  background-color: #F1F6F9;
}
@media screen and (max-width: 767px) {
  .queryFirst__select__buttons .selectBtn {
    position: relative;
  }
  .queryFirst__select__buttons .selectBtn::after {
    content: "";
    display: block;
    width: 18px;
    height: 18px;
    background-image: url(../images/common/icon_arw_btn2.svg);
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: contain;
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translate(0, -50%) rotate(90deg);
  }
  .queryFirst__select__buttons .selectBtn.on::after {
    background-image: url(../images/common/icon_arw_btn3.svg);
  }
}
.queryFirst__select__contents__box {
  display: none;
  padding: 30px 0;
}
.queryFirst__select__contents__box.open {
  display: block;
}
.queryFirst__select__contents__box .box__title {
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  line-height: 1.875;
  letter-spacing: 0;
}
.queryFirst__select__contents__box h4 {
  margin: 40px 0 15px;
  font-size: 15px;
  font-weight: 700;
}
.queryFirst__select__contents__box ol {
  margin: 15px 0;
}
.queryFirst__select__contents__box p.link_2_1 {
  margin: 25px 0 15px;
}
.queryFirst__select__contents__box p.link_2_1 a {
  margin: 0 auto;
  width: 100%;
  height: 79px;
  max-width: 345px;
  text-align: center;
  line-height: 1.4;
}
.queryFirst__select__contents__box p.link_2_2 {
  margin: 25px 0 15px;
}
.queryFirst__select__contents__box p.link_2_2 a {
  margin: 0 auto;
  width: 100%;
  height: 58px;
  max-width: 345px;
  text-align: center;
  line-height: 1.4;
}
.queryFirst__select__contents__box p.contactBtn {
  margin: 25px 0 15px;
}
.queryFirst__select__contents__box p.telTxt a {
  margin-right: 5px;
  font-size: 18px;
  font-weight: 700;
}
.queryFirst__select__contents__box p.attent {
  margin: 0;
}
.queryFirst__select__contents__box p.attent small {
  display: block;
  font-size: 13px;
  line-height: 1.9;
}
.queryFirst__tbl {
  margin-top: 20px;
  text-align: left;
  border-bottom: 1px solid #C0DBEC;
}
.queryFirst__tbl th, .queryFirst__tbl td {
  font-size: 13px;
  font-weight: 400;
  border-top: 1px solid #C0DBEC;
}
.queryFirst__tbl th ul, .queryFirst__tbl td ul {
  margin-top: 0;
  padding-left: 10px;
}
.queryFirst__tbl th ul > li::before, .queryFirst__tbl td ul > li::before {
  width: 4px;
  height: 4px;
  transform: translate(-10px, 10px);
}
.queryFirst__tbl th {
  padding: 12px 12px 12px 10px;
  width: 130px;
  background-color: #F1F6F9;
}
.queryFirst__tbl td {
  padding: 12px 0 12px 10px;
  width: calc(100% - 130px);
  vertical-align: top;
}
.queryFirst__tbl td p.attent {
  padding-left: 10px;
}
.queryFirst__tbl td p.attent small {
  display: block;
  font-size: 13px;
  line-height: 1.9;
  position: relative;
}
.queryFirst__tbl td p.attent small::before {
  content: "※";
  position: absolute;
  left: -1.2em;
  top: 0;
}
.queryFirst__contents__box {
  margin: 30px 0;
}
.queryFirst__contents__box:nth-of-type(1) {
  margin-top: 0;
}
.queryFirst__contents__box .box__title {
  margin-top: 25px;
  margin-bottom: 15px;
  font-size: 15px;
  font-weight: 700;
}
.queryFirst__contents__box figure {
  margin: 20px auto;
}
.queryFirst__contents__box figure img {
  display: block;
}
.queryFirst__contents__box .link_faq {
  margin-top: 10px;
  text-align: right;
}
.queryFirst__contents__box .link_faq a {
  padding-left: 30px;
  font-size: 14px;
  font-weight: 700;
  text-decoration: none;
  position: relative;
}
.queryFirst__contents__box .link_faq a::before {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  background-image: url(../images/common/icon_arw_btn3.svg);
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: contain;
  position: absolute;
  left: 0;
  top: 50%;
  transition: all 0.3s ease;
  transform: translateY(-50%);
}
.queryFirst__finish__message {
  margin-top: 30px;
  text-align: center;
}
.queryFirst__finish__message p {
  padding: 18px 4px;
  font-size: 18px;
  font-weight: 700;
  line-height: 1;
  background-color: #F1F6F9;
}
.queryFirst__pageLink {
  padding: 40px 20px;
  background-color: #0072BC;
}
.queryFirst__pageLink h2 {
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
}
.queryFirst__pageLink .cont {
  margin: 15px auto 0;
  max-width: 345px;
}
.queryFirst__pageLink a {
  display: flex;
  color: #333;
  text-decoration: none;
  border-radius: 6px;
  overflow: hidden;
  position: relative;
}
.queryFirst__pageLink a::after {
  content: "";
  display: block;
  width: 20px;
  height: 14px;
  background-image: url(../images/common/icon_blank_blue.svg);
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: contain;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translate(0, -50%);
}
.queryFirst__pageLink .img {
  display: flex;
  align-items: center;
  width: 21.5%;
  background: #00cbf5;
  background: -moz-linear-gradient(left, #00cbf5 0%, #00cede 100%);
  background: -webkit-linear-gradient(left, #00cbf5 0%, #00cede 100%);
  background: linear-gradient(to right, #00cbf5 0%, #00cede 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#00cbf5", endColorstr="#00cede",GradientType=1 );
}
.queryFirst__pageLink .img img {
  display: block;
}
.queryFirst__pageLink .textarea {
  padding: 13px 35px 13px 15px;
  width: 78.5%;
  background-color: #fff;
}
@media screen and (min-width: 385px) {
  .queryFirst__pageLink .textarea {
    padding: 15px 45px 15px 15px;
  }
}
.queryFirst__pageLink .textarea .t1 {
  font-size: 14px;
  font-weight: 500;
}
.queryFirst__pageLink .textarea .ttl {
  font-size: 18px;
  font-weight: 700;
}
.queryFirst__pageLink .textarea .t2 {
  font-size: 13px;
}
.queryFirst .toolBtn {
  margin: 20px 0 15px;
}
.queryFirst .toolBtn a {
  display: block;
  margin: 0 auto;
  width: 100%;
  height: 60px;
  max-width: 690px;
  text-indent: -9999px;
  overflow: hidden;
  background-image: url(../images/query/first/tool_btn_sp.png);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
}
.queryFirst .toolBtn + .attent {
  text-align: center;
}
.queryFirst__nextstep {
  margin: 0;
  padding: 0 25px 0 0;
  width: 53px;
  height: auto;
  font-size: 12px;
  border: 0;
  position: absolute;
  right: 15px;
  top: 100%;
  transform: translateY(10px);
}
.queryFirst__nextstep::after {
  width: 18px;
  height: 18px;
  right: 0;
}
.queryFirst__nextstep:hover {
  color: inherit;
  background: none;
  opacity: 0.7;
}

@media screen and (min-width: 768px) {
  .queryFirst .page-header .img {
    width: 100%;
    max-width: 365px;
  }
  .queryFirst__pageLink h2 {
    font-size: 18px;
    letter-spacing: 0;
  }
  .queryFirst__pageLink .cont {
    margin: 30px auto 0;
    max-width: 900px;
  }
  .queryFirst__pageLink .img {
    width: 38.6%;
  }
  .queryFirst__pageLink .textarea {
    display: flex;
    flex-wrap: wrap;
    width: 61.4%;
  }
  .queryFirst__pageLink .textarea .t1 {
    width: 40%;
    font-size: 14px;
  }
  .queryFirst__pageLink .textarea .ttl {
    width: 60%;
    font-size: 22px;
    font-weight: 700;
  }
  .queryFirst__pageLink .textarea .t2 {
    width: 100%;
    font-size: 14px;
  }
}
@media screen and (min-width: 992px) {
  .queryFirst__block {
    padding: 80px 15px;
  }
  .queryFirst__block .inner {
    margin: 0 auto;
    padding: 0;
  }
  .queryFirst__block ul {
    margin-top: 18px;
  }
  .queryFirst__step__intro {
    padding: 75px 15px 60px;
    text-align: center;
  }
  .queryFirst__step__anchor {
    padding: 0 15px 50px;
    max-width: 100%;
  }
  .queryFirst__step__anchor .flexBox {
    display: flex;
    flex-wrap: wrap;
  }
  .queryFirst__step__anchor .flexItem {
    margin: 15px 0 !important;
    width: 33.3333%;
  }
  .queryFirst__step__anchor .flexItem:not(:last-child) {
    position: relative;
  }
  .queryFirst__step__anchor .flexItem:not(:last-child)::after {
    content: "";
    display: block;
    width: 30px;
    height: 20px;
    background-image: url(../images/common/icon_arw_down.svg);
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: contain;
    position: absolute;
    right: 4px;
    top: 50%;
    transform: translate(0, -50%) rotate(-90deg);
  }
  .queryFirst__step__anchor .anchorBtn {
    margin-right: 42px;
    height: 100%;
  }
  .queryFirst__step__anchor .anchorBtn::after {
    width: 24px;
    height: 24px;
  }
  .queryFirst__step__anchor .anchorBtn .step {
    width: 74px;
    font-size: 15px;
  }
  .queryFirst__step__anchor .anchorBtn .step .num {
    font-size: 20px;
  }
  .queryFirst__step__anchor .anchorBtn .ttl {
    padding: 8px 14px;
    width: calc(100% - 74px);
    font-size: 14px;
  }
  .queryFirst__step__anchor .anchorBtn:hover {
    opacity: 1;
    background-color: #008EEB;
    border: 1px solid #008EEB;
  }
  .queryFirst__step__anchor .anchorBtn:hover::after {
    margin-top: 10px;
  }
  .queryFirst__step__block {
    padding: 20px 15px;
    border: 0;
  }
  .queryFirst__step__block > .inner {
    padding: 40px 100px 50px;
    border: 1px solid #C0DBEC;
  }
  .queryFirst__step__block.step01 .queryFirst__select__buttons .flexItem {
    width: 25%;
  }
  .queryFirst__step__block.step02::before, .queryFirst__step__block.step03::before, .queryFirst__step__block.step04::before, .queryFirst__step__block.step05::before, .queryFirst__step__block.step06::before {
    width: 50px;
    height: 33px;
  }
  .queryFirst__step__body ul > li::before {
    transform: translate(-24px, 16px);
  }
  .queryFirst__select__buttons {
    border-bottom: 1px solid #0072BC;
  }
  .queryFirst__select__buttons .flexBox {
    margin: 0 -15px;
  }
  .queryFirst__select__buttons .flexItem {
    margin: 0 0 -1px;
    padding: 0 15px;
    width: 33.333%;
  }
  .queryFirst__select__buttons .selectBtn {
    margin-bottom: -1px;
    padding: 10px;
    font-weight: 700;
    border-radius: 6px 6px 0 0;
  }
  .queryFirst__select__buttons .selectBtn.on {
    border-bottom: 1px solid #FFF;
  }
  .queryFirst__select__contents__box p.link_2_1 a {
    width: 100%;
    height: 108px;
    max-width: 625px;
  }
  .queryFirst__select__contents__box p.link_2_1 + .attent {
    margin: 0 auto;
    max-width: 625px;
  }
  .queryFirst__select__contents__box p.link_2_2 {
    margin: 25px 0 15px;
  }
  .queryFirst__select__contents__box p.link_2_2 a {
    width: 100%;
    height: 72px;
    max-width: 625px;
  }
  .queryFirst__tbl {
    margin-top: 40px;
  }
  .queryFirst__tbl th, .queryFirst__tbl td {
    padding: 10px;
    font-size: 17px;
  }
  .queryFirst__tbl th ul, .queryFirst__tbl td ul {
    margin-top: 0;
    padding-left: 18px;
  }
  .queryFirst__tbl th ul > li::before, .queryFirst__tbl td ul > li::before {
    width: 8px;
    height: 8px;
    transform: translate(-18px, 16px);
  }
  .queryFirst__tbl th {
    padding: 20px 0 20px 30px;
    width: 340px;
    background-color: #F1F6F9;
  }
  .queryFirst__tbl td {
    padding: 20px 0 20px 40px;
    width: calc(100% - 340px);
  }
  .queryFirst__pageLink {
    padding: 60px 20px;
  }
  .queryFirst__pageLink a::after {
    width: 26px;
    height: 18px;
    right: 40px;
  }
  .queryFirst__pageLink .textarea {
    padding: 40px 67px 40px 60px;
  }
  .queryFirst__pageLink .textarea .t1 {
    width: 45%;
    font-size: 18px;
  }
  .queryFirst__pageLink .textarea .ttl {
    width: 55%;
    font-size: 24px;
    font-weight: 700;
  }
  .queryFirst__pageLink .textarea .t2 {
    margin-top: 5px;
    width: 94%;
    font-size: 16px;
  }
  .queryFirst .toolBtn {
    margin: 20px 0 15px;
  }
  .queryFirst .toolBtn a {
    width: 100%;
    height: 90px;
    max-width: 408px;
    background-image: url(../images/query/first/tool_btn.png);
    transition: all 0.3s ease;
    position: relative;
  }
  .queryFirst .toolBtn a::after {
    content: "";
    display: block;
    width: 24px;
    height: 24px;
    background-image: url(../images/common/icon_arw_btn2.svg);
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: contain;
    position: absolute;
    right: 30px;
    top: 50%;
    transition: all 0.3s ease;
    transform: translateY(-50%);
  }
  .queryFirst .toolBtn a:hover {
    background-image: url(../images/query/first/tool_btn_on.png);
    opacity: 1;
  }
  .queryFirst .toolBtn a:hover::after {
    right: 25px;
  }
}
@media screen and (min-width: 1100px) {
  .queryFirst__step__anchor .anchorBtn .ttl {
    font-size: 18px;
  }
  .queryFirst__step__block.step06 {
    margin-bottom: 80px;
  }
  .queryFirst__step__head .step__title__num {
    padding: 10px 20px;
  }
  .queryFirst__step__head .step__title__txt {
    padding: 10px 20px;
  }
  .queryFirst__step__head .step__title__txt .ttl {
    font-size: 24px;
  }
  .queryFirst__step__head .step__leadTxt {
    margin-top: 30px;
  }
  .queryFirst__step__head .btn_text {
    text-align: center;
  }
  .queryFirst__step__body {
    margin-top: 40px;
  }
  .queryFirst__step__body ul > li {
    line-height: 2.07;
  }
  .queryFirst__step__body ul > li::before {
    transform: translate(-24px, 14px);
  }
  .queryFirst__select__buttons .selectBtn {
    padding: 20px 5px;
    font-size: 16px;
  }
  .queryFirst__select__contents__box {
    padding: 54px 0;
  }
  .queryFirst__select__contents__box .box__title {
    font-size: 21px;
    letter-spacing: 0.05em;
  }
  .queryFirst__select__contents__box h4 {
    font-size: 18px;
  }
  .queryFirst__select__contents__box p.telTxt a {
    font-size: 20px;
  }
  .queryFirst__tbl {
    margin-top: 35px;
  }
  .queryFirst__tbl th, .queryFirst__tbl td {
    padding: 15px 0 15px 30px;
  }
  .queryFirst__tbl th {
    padding: 15px 0 15px 30px;
  }
  .queryFirst__tbl td {
    padding: 15px 0 15px 40px;
  }
  .queryFirst__contents__box .box__title {
    font-size: 18px;
  }
  .queryFirst__contents__box figure {
    max-width: 750px;
  }
  .queryFirst__contents__box figure.step_1_1 {
    max-width: 408px;
  }
  .queryFirst__contents__box figure.step_3_1 {
    max-width: 750px;
  }
  .queryFirst__contents__box figure.step_4_1 {
    max-width: 750px;
  }
  .queryFirst__contents__box figure.step_4_2 {
    max-width: 750px;
  }
  .queryFirst__contents__box .link_faq a {
    padding-left: 35px;
    font-size: 16px;
  }
  .queryFirst__contents__box .link_faq a::before {
    width: 24px;
    height: 24px;
  }
  .queryFirst .toolBtn {
    margin: 48px 0 15px;
  }
  .queryFirst__finish__message {
    margin-top: 60px;
  }
  .queryFirst__finish__message p {
    padding: 20px 4px;
    font-size: 24px;
  }
}
.queryFirst__attention {
  margin-top: 30px;
}
.queryFirst__attention h3 {
  padding-left: 7px;
  color: #0072BC;
  font-size: 17px;
  line-height: 24px;
  border-left: 5px solid #0072BC;
}

@media screen and (min-width: 1100px) {
  .queryFirst__attention {
    margin-top: 45px;
  }
  .queryFirst__attention h3 {
    padding-left: 18px;
    font-size: 20px;
    letter-spacing: 0.05em;
    border-left: 7px solid #0072BC;
  }
  .queryFirst__attention ul {
    margin-top: 14px;
  }
  .queryFirst__attention ul li {
    margin-top: 3px;
    font-size: 16px;
    line-height: 2.25;
  }
}
.queryFirst__send {
  margin-top: 50px;
}
.queryFirst__send h3 {
  padding-left: 20px;
  color: #0072BC;
  line-height: 24px;
  border-left: 7px solid #0072BC;
}